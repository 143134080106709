import React from 'react';
import { Case, Switch } from 'react-if';
import { observer } from 'mobx-react-lite';
import { FirstStep } from '../../Layout/components/Login/Steps/FirstStep';
import { SecondStep } from '../../Layout/components/Login/Steps/SecondStep';
import { LoginAttempts } from '../../Layout/components/Login/Steps/loginAttempts';
import { LackPersonalArea } from '../../Layout/components/Login/Steps/lackPersonalArea';
import { Modal } from '../../Modal';
import { useAuthStore } from '../../../providers/RootStoreProvider';

export const ModalAuth = observer(({ isMobile, setToggledModal }) => {
  const store = useAuthStore();
  // const setToggledModal = toggledModal => store.setToggledModal(toggledModal);
  return (
    <>
      <Modal
        size="dialog"
        onOpen={setToggledModal}
        isOpen={store.toggledModal}
        title="Вход"
        bottomGap={20}
        isMobile={isMobile}
      >
        <Switch>
          <Case condition={store.step === 'phoneInput'}>
            {store.strategySignIn !== 'authSteps' ? (
              <FirstStep />
            ) : (
              <SecondStep />
            )}
          </Case>
          <Case condition={store.step === 'codeInput'}>
            <SecondStep />
          </Case>
          <Case condition={store.step === 'loginAttempts'}>
            <LoginAttempts />
          </Case>
          <Case condition={store.step === 'lackPersonalArea'}>
            <LackPersonalArea />
          </Case>
        </Switch>
      </Modal>
    </>
  );
});
