import React from 'react';
import styles from './avatar.module.sass';
import { Text } from '../Text';
import { IconSVG } from '../SpriteSVG';
// import avatar from '../../public/icons/avatar.svg';

export const Avatar = ({ name, userPick, ...props }) => (
  <div className={styles.avatar} {...props}>
    {!name ? (
      // <img className={styles.avatarImg} src={userPick || avatar} alt={name} />
      <div className={styles.avatarImg}>
        {userPick || <IconSVG name={'common/avatar'} width={40} height={40} />}
      </div>
    ) : (
      // <img
      //   className={styles.avatarImg}
      //   src={ userPick || <AvatarImg />}
      //   alt={name}
      // />
      <div className={styles.avatarName}>
        <Text variant="linkAsButtonPrimarySm" transform="uppercase">
          {name}
        </Text>
      </div>
    )}
  </div>
);
