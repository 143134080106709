import React from 'react';
import Link from 'next/link';
import { Icon } from '../Icon';
// import white from '../../public/icons/white.svg';
// import Logotype from '../../public/icons/logotype.svg';
import { IconSVG } from '../SpriteSVG';

export const Logo = ({ link = '/', w = 208, h = 32, target = false }) => {
  const linkProps = target ? { target: '_blank', rel: 'noreferrer' } : {};
  return (
    <>
      <Link href={link}>
        <a {...linkProps}>
          <Icon
            icon={<IconSVG name={'common/logotype'} width={w} height={h} />}
            title="Логотип. Круиз Онлайн."
            alt="Логотип. Круиз Онлайн."
          />
        </a>
      </Link>
    </>
  );
};
