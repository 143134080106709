import React from 'react';
import { useRouter } from 'next/router';
import { Anchor, Text } from '../../Text';
import styles from './loginMenu.module.sass';
import { useAuthStore } from '../../../providers/RootStoreProvider';
import { checkedDev } from '../../../utils/checkedDev';

export const LoginMenu = ({ onClick, isManager }) => {
  const store = useAuthStore();
  const router = useRouter();

  return (
    <div className={styles.wrapper}>
      <div className={styles.item}>
        <div className={styles.orders}>
          <Anchor
            variant="text-7"
            color={
              router.route !== '/my/orders' ? 'primaryColor' : 'dark-color'
            }
            href="/my/orders"
            target="_blank"
            onClick={() => onClick()}
          >
            Мои заказы
          </Anchor>
          {/*  <div className={styles.ordersCount}>
            <Text
              variant="text"
              color={
                router.route !== '/my/orders' ? 'primaryColor' : 'dark-color'
              }
            >
              {count}
            </Text>
          </div> */}
        </div>
        <div>
          <Anchor
            variant="text-7"
            color={router.route !== '/my/main' ? 'primaryColor' : 'dark-color'}
            href="/my/main"
            target="_blank"
            onClick={() => onClick()}
          >
            Мой профиль
          </Anchor>
        </div>
        {isManager && (
          <div>
            <Anchor
              variant="text-7"
              color="primaryColor"
              href={`${checkedDev('/adm_n/index.html#!')}`}
              target="_blank"
              onClick={() => onClick()}
            >
              Админка
            </Anchor>
          </div>
        )}
        <div>
          <Anchor
            variant="text-7"
            color="primaryColor"
            href="/reviews"
            target="_blank"
            onClick={() => onClick()}
          >
            Отзывы
          </Anchor>
        </div>
        <div className={styles.buttonWrapper}>
          <Text
            variant="text-7"
            color="primaryColor"
            onClick={() => {
              store.logOut();
              onClick();
            }}
          >
            Выход
          </Text>
        </div>
      </div>
    </div>
  );
};
