// TODO https://gist.github.com/Kafkae/e9f58fae735961e688319fab6a4fe7d8
import React, { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import { observer } from 'mobx-react-lite';
import { Else, If, Then } from 'react-if';
import { useClickAway } from 'react-use';
import cc from 'classcat';
import {
  useAuthStore,
  useFavorites,
  useMyCabinetStore,
} from '../../../../providers/RootStoreProvider';
import styles from './login.module.sass';
import { Button } from '../../../Button';
import { Avatar } from '../../../Avatar';
import { Text } from '../../../Text';
import { isClient } from '../../../../utils/isClient';
import { LoginMenu } from '../../../PrivateOffice/LoginMenu';
import { Icon } from '../../../Icon';
import { IconSVG } from '../../../SpriteSVG';
import { ModalAuth } from '../../../Features/ModalAuth/ModalAuth';

export const Login = observer(({ isMobile, isOutsPage, isStep }) => {
  const store = useAuthStore();
  const count = useFavorites().items.length;
  const ref = useRef();
  const [showMenu, setShowMenu] = useState(false);
  const setToggledModal = (toggledModal, strategy) =>
    store.setToggledModal(toggledModal, strategy);

  useEffect(() => {
    if (!store.toggledModal) {
      return;
    }
    document.body.classList.add('body-scroll-lock');

    // eslint-disable-next-line consistent-return
    return () => {
      document.body.classList.remove('body-scroll-lock');
      setTimeout(() => {
        // убираем баг с модалкой капчи
        document.body.style.overflow = '';
      }, 500);
    };
  }, [store.toggledModal]);

  const myStore = useMyCabinetStore();

  useClickAway(ref, () => {
    if (showMenu) {
      setShowMenu(false);
    }
  });

  return (
    <>
      <div className={styles.wrapper}>
        {!isStep && isClient && (
          <Link href="/cart">
            <a className={styles.favorite}>
              <div className={styles.heart}>
                <Icon
                  icon={<IconSVG name={'common/cart'} width={20} height={20} />}
                  alt="Корзина"
                  title="Корзина"
                />
                {count > 0 && <span>{count}</span>}
              </div>
              <span className={styles.favoriteText}>Корзина</span>
            </a>
          </Link>
        )}
        <If condition={store.isLoggedIn}>
          <Then>
            <div className={styles.login}>
              <If condition={isMobile}>
                <Then>
                  <div>
                    <Text
                      className={styles.lkButtonWrapper}
                      onClick={() => setShowMenu(true)}
                    >
                      <Avatar />
                    </Text>
                    {showMenu && (
                      <div ref={ref}>
                        <LoginMenu
                          count={myStore?.nextOrder?.length}
                          onClick={click => {
                            setShowMenu(click);
                          }}
                          isManager={store.isManager}
                        />
                      </div>
                    )}
                  </div>
                </Then>
                <Else>
                  <div className={styles.loginContentButton}>
                    <div>
                      <Avatar className={styles.loginContentAvatar} onClick={() => setShowMenu(true)} />
                    </div>
                    <div className={styles.buttonWrapper}>
                      <Text variant="text-5" onClick={() => setShowMenu(true)}>
                        Личный кабинет
                      </Text>
                    </div>
                    {showMenu && (
                      <div ref={ref}>
                        <LoginMenu
                          count={myStore?.nextOrder?.length}
                          onClick={click => {
                            setShowMenu(click);
                          }}
                          isManager={store.isManager}
                          isB2bMode={store.isB2bMode}
                        />
                      </div>
                    )}
                  </div>
                </Else>
              </If>
            </div>
          </Then>
          <Else>
            {!isOutsPage && (
              <Button
                onClick={() => {
                  setToggledModal(true);
                }}
                size={isMobile ? 'sm' : 'md'}
                className={cc([styles.button], {
                  [styles.buttonGap]: isMobile,
                })}
              >
                Войти
              </Button>
            )}
          </Else>
        </If>
        <ModalAuth isMobile={isMobile} setToggledModal={setToggledModal} />
      </div>
    </>
  );
});
