export const riverProfitShips = [
  {
    nameProfitShip: 'Волга Стар',
    linkProfitShip: '/ships/volgastar',
  },
  {
    nameProfitShip: 'Иван Бунин',
    linkProfitShip: '/ships/ivan-bunin',
  },
  {
    nameProfitShip: 'Княжна Анастасия',
    linkProfitShip: '/ships/knyazhna-anactaciya',
  },
  {
    nameProfitShip: 'Леонид Красин',
    linkProfitShip: '/ships/leonid-kracin',
  },
  {
    nameProfitShip: 'Сергей Кучкин',
    linkProfitShip: '/ships/cergey-kuchkin',
  },
];

export const riverBestReviewsShips = [
  {
    nameBestRevShip: 'Александр Грин',
    linkBestRevShip: '/ships/alekcandr-grin',
  },
  {
    nameBestRevShip: 'Андрей Рублев',
    linkBestRevShip: '/ships/andrey-rublev',
  },

  {
    nameBestRevShip: 'Лебединое озеро',
    linkBestRevShip: '/ships/lebedinoe-ozero',
  },
  {
    nameBestRevShip: 'Ленин',
    linkBestRevShip: '/ships/lenin',
  },
  {
    nameBestRevShip: 'Леонид Красин',
    linkBestRevShip: '/ships/leonid-kracin',
  },
  {
    nameBestRevShip: 'Максим Горький',
    linkBestRevShip: '/ships/makcim-gor-kiy',
  },
  {
    nameBestRevShip: 'Маленький принц',
    linkBestRevShip: '/ships/malenkiy_printz',
  },
  {
    nameBestRevShip: 'Мустай Карим',
    linkBestRevShip: '/ships/mustai-karim',
  },
  {
    nameBestRevShip: 'Россия',
    linkBestRevShip: '/ships/rossiya',
  },
  {
    nameBestRevShip: 'Юрий Никулин',
    linkBestRevShip: '/ships/yury-nikulin',
  },
];

export const seaProfitShips = [
  {
    nameProfitShip: 'Astoria Grande',
    linkProfitShip: '/ships/astoria-grande',
  },
  {
    nameProfitShip: 'MSC World Europa',
    linkProfitShip: '/ships/msc-world-europa',
  },
  {
    nameProfitShip: 'MSC Lirica',
    linkProfitShip: '/ships/msc-lirica',
  },
  {
    nameProfitShip: 'MSC Opera',
    linkProfitShip: '/ships/msc-opera',
  },
  {
    nameProfitShip: 'MSC Splendida',
    linkProfitShip: '/ships/msc-splendida',
  },

  // {
  //   nameProfitShip: 'Discovery Princess',
  //   linkProfitShip: '',
  // },
];

export const seaBestReviewsShips = [
  {
    nameBestRevShip: 'MSC Armonia',
    linkBestRevShip: '/ships/msc-armonia',
  },
  {
    nameBestRevShip: 'MSC Fantasia',
    linkBestRevShip: '/ships/msc-fantasia',
  },
  {
    nameBestRevShip: 'MSC Orchestra',
    linkBestRevShip: '/ships/msc-orchestra',
  },
  {
    nameBestRevShip: 'MSC Grandiosa',
    linkBestRevShip: '/ships/msc-grandiosa',
  },
  {
    nameBestRevShip: 'MSC Poesia',
    linkBestRevShip: '/ships/msc-poesia',
  },
  {
    nameBestRevShip: 'MSC Sinfonia',
    linkBestRevShip: '/ships/msc-sinfonia',
  },
  {
    nameBestRevShip: 'MSC Virtuosa',
    linkBestRevShip: '/ships/msc-virtuosa',
  },
  {
    nameBestRevShip: 'MSC Meraviglia',
    linkBestRevShip: '/ships/msc-meraviglia',
  },
  {
    nameBestRevShip: 'MSC Magnifica',
    linkBestRevShip: '/ships/msc-magnifica',
  },
  {
    nameBestRevShip: 'MSC Musica',
    linkBestRevShip: '/ships/msc-musica',
  },
  // {
  //   nameBestRevShip: 'Enchanted Princess',
  //   linkBestRevShip: '',
  // },
  // {
  //   nameBestRevShip: 'Majestic Princess',
  //   linkBestRevShip: '',
  // },
];
